// --------------------
// setDonationPage.js |
// --------------------
// Page controls the initial donation page. Controls both currentDonation and addDonation which set the initial values. 
// =========================================================================================================================

// DEPENDENCIES
// ========================================
import React, {useState, useEffect} from 'react'
import { useOutletContext, useNavigate, Navigate } from "react-router-dom";
import axios from 'axios';
const { DateTime } = require("luxon");

// Server URL 
const server = require("../../connections/serverURL");
var connection_server = server.getServer();
import DOMPurify from 'dompurify';


// Components
import HolidayButton from '../sub-components/holidayButton.jsx'

// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import  {faSchool } from '@fortawesome/free-solid-svg-icons'
import  {faMoon } from '@fortawesome/free-solid-svg-icons'
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons'

// SPECIFY CAUSE PAGE
// ========================================
function SpecifyCause(){

	// Context Data for tracking the session and server interactions 
  	const outletContext = useOutletContext();
   	const sessionID = outletContext.sessionID;
  	const serverURL = outletContext.serverURL;
  	const currentDonation = outletContext.currentDonation;
	const setCurrentDonation = outletContext.setCurrentDonation;
  	const orgID = outletContext.orgID;
  	const orgData = outletContext.orgData;
   	const dbsClicked = outletContext.dbsClicked;
  	const setdbsClicked = outletContext.setdbsClicked;
  	const donationCauses = outletContext.donationCauses;
  	const setDonationCauses = outletContext.setDonationCauses;
  	const specifiedCause = outletContext.specifiedCause;
  	const setSpecifiedCause = outletContext.setSpecifiedCause;
	const setSubscriptionDonation = outletContext.setSubscriptionDonation
	const setSubscriptionFrequency = outletContext.setSubscriptionFrequency;
	const setSubscriptionCount = outletContext.setSubscriptionCount;
	const setToggleSubscriptionCount = outletContext.setToggleSubscriptionCount;
	const isHolidayActive = outletContext.isHolidayActive;
	const setIsHolidayActive = outletContext.setIsHolidayActive;

  	// Check for existence of Designation data
  	const [hasDesignationData, setHasDesignationData] = useState({})
	const [isFriday, setIsFriday] = useState(false);

  	// React router navigation hook
	let navigate = useNavigate();

	// Retrieve the list of designations
	useEffect(() => {

		// Build the URL to call
	 	var designationURL = connection_server + '/designations/?orgid=' + orgID; 

	 	// Axios Call
	 	const designationAPICall = async() => {
	 		const result = await axios(designationURL);
	 		// console.log(result);
	 		setDonationCauses(result.data.designations);
	 		setHasDesignationData(true);
	 		// console.log(result.data);
	 		// console.log(donationCauses);
	 	};

	 	// Run the API call
	 	designationAPICall();		

	}, [orgID])

	// Check if its Jummah 
	useEffect(() => {
		
		var today = DateTime.local().weekday;
		if (today == 5 || today == 4 || today == 6) {
			setIsFriday(true);
		}
		else {
			setIsFriday(false);
		}
	
	}, []);

	// Handle user descriptions with links
	function renderUserInput(input) {
		const sanitizedInput = DOMPurify.sanitize(input, { ALLOWED_TAGS: ['a'], ALLOWED_ATTR: ['href'] });
		const html = `<div>${sanitizedInput}</div>`;
		return <div dangerouslySetInnerHTML={{ __html: html }} />;
	  }

	// Button Renderer 
	function renderButtons() {
		if(donationCauses.length>0){	
			return( 
				donationCauses.map((designation, i) => <button key={i} 
					
					onClick={() => { 
						setSpecifiedCause(designation); 
						setCurrentDonation(0);
						navigate("../Set-Donation/" + designation.pathname); 	
					}}
				id={"designation-" + i} className="transition-all duration-75 mt-1 rounded-2xl text-center opacity-70 hover:opacity-100 active:opacity-100 text-black  active:drop-shadow-sm border-4 hover:border-theme-100 font-bold bg-slate-100 active:scale-90 active:text-sm text-sm py-3 py-5 3xl:py-6 3xl:text-lg 3xl:mb-2 w-full drop-shadow-lg">{designation.title}</button>)				
				);
			}
		else{
			return(<div></div>)
		}
	}

	// Render Function	
	if(orgData.account_features.specify_cause) {
		return (
			<div>

				<hr className="my-3 w-11/12 mx-auto lg:mb-2"/>

				{/* General Donation Button */}
				<div className="w-11/12 mx-auto mb-1">
					<button 
						onClick={() => { 
							setSpecifiedCause({});
							setCurrentDonation(0);
			  				navigate("../Set-Donation/"); 
			  			}}
						id="beginBtn" className="mt-2 rounded-md text-center text-white opacity-100 bg-theme-100 hover:bg-theme-200 active:bg-theme-200 text-xl py-3 py-4 font-bold w-full shadow-md shadow-slate-400 leading-3 3xl:py-5"> General Donation<span className="text-xl"></span><br/> <span className="text-sm font-light opacity-50 italic 3xl:text-base 3xl:mt-3"><FontAwesomeIcon className="pl-2" icon={faCircleCheck}/> Where Needed Most</span> 
					</button>
				</div>

				{/* Islamic Holiday Button */}
				<div className="3xl:mb-1 w-11/12 mx-auto">
					<HolidayButton isHolidayActive = {isHolidayActive} setIsHolidayActive = {setIsHolidayActive} setSubscriptionDonation = {setSubscriptionDonation} setSubscriptionFrequency = {setSubscriptionFrequency} setToggleSubscriptionCount= {setToggleSubscriptionCount} setSubscriptionCount = {setSubscriptionCount} setCurrentDonation = {setCurrentDonation} setSpecifiedCause = {setSpecifiedCause} holidayFeatureEnabled = {orgData.account_features.islamic_holidays}/>
				</div>

				{/* Weekly Jummah Donation Button */}
				<div className="w-11/12 mx-auto">
					<button 
						onClick={() => { 
							setSubscriptionDonation(true);
							setToggleSubscriptionCount(true);
							setCurrentDonation(10);
							setSubscriptionFrequency("week");
							setSubscriptionCount("until stopped");
							setSpecifiedCause({title: "Jummah Pledge", pathname: "jummah-pledge", goalInclusion: true});
							navigate("../Set-Donation"); 	
						}}

						id="beginBtn" className={` 	mt-2 rounded-md text-center text-stone-700 opacity-100 text-xl py-3 py-4 font-bold w-full shadow-md shadow-slate-400 leading-3 3xl:mb-1 3xl:mt-3 ${isFriday ? "block " : "hidden"} ${isHolidayActive ? "hidden" : " bg-amber-300 hover:bg-amber-400 active:bg-amber-400 "}   `}> Jummah Pledge<span className="text-xl"><FontAwesomeIcon className="pl-2" icon={faClockRotateLeft}/></span><br/> <span className="text-sm font-light opacity-50 italic">Automate a Weekly Jummah Donation</span> 
					</button>
				</div>

				{/*Islamic School Button*/}
				<div className={`mb-2 ${orgData.account_features.islamic_school ? "" : "hidden"}`} >
					<div className="w-11/12 mx-auto ">

						<button 
							onClick={() => { 
								navigate("../Islamic-School"); 
							}}

							id="specifyBtn" className="mt-3 rounded-md
							text-center text-slate-800 opacity-100 border
							bg-fuchsia-200 hover:bg-fuchsia-300
							active:bg-blue-200 
							hover:text-black py-3  w-full
							mr-2 shadow-md 3xl:mb-1 font-semibold text-lg 3xl:text-xl 3xl:font-semibold 3xl:py-3 leading-3"> Sunday School Registration  <span className="text-xl"><FontAwesomeIcon className="pl-2 pt-1" icon={faSchool}/></span><br/> <span className="text-sm font-light opacity-50 italic">Sign Up for the Upcoming Sunday School Program</span> 
						</button> 
					</div> 
				</div>

				{/* Cause Buttons */}
				<div className="grid grid-cols-2 gap-2 w-11/12 mx-auto mt-3">
					{renderButtons()}
				</div>

				{/* Divider */}
				<hr className = "mb-5 mt-3 w-11/12 mx-auto" />

				{/* Account Messages */}
				<div className="text-neutral-600 ">
					<div className="mx-auto ml-7 mr-2"><p className = "text-sm 3xl:text-base font-bold leading-6">{orgData.account_message.title}</p></div>
					<div className="mx-auto ml-7 pr-3"><div className = "text-sm 3xl:text-base leading-6 3xl:leading-8 3xl:mr-2">{renderUserInput(orgData.account_message.subtitle)}</div></div>
				</div>


				{/*Page Footer*/}				
				{/* <hr className="mt-4 mb-2"/> */}
				
			</div>
		)		
	}

	else {
		navigate("../Set-Donation")
	}



}

// EXPORT COMPONENT
// ========================================
export default SpecifyCause;