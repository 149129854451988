import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";


export const Auth0ProviderWithConfig = ({ children }) => {
  const domain = "https://auth.givemasjid.com"
  const clientId = "80Fs82d1WjCZdpQgX32BOcRIL6ldf52r"
  var redirectUri = ""

  if(process.env.REACT_APP_DEPLOYMENT == "React" || process.env.REACT_APP_DEPLOYMENT == "Express"){
     redirectUri = "http://localhost:3000/dashboard"
     console.log(redirectUri);
  }

  else {
    redirectUri = "https://donate.givemasjid.com/dashboard"
    console.log(redirectUri);
  }

  // console.log(redirectUri);
  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  // console.log('Domain:', domain);
  // console.log('ClientId:', clientId);
  // console.log('RedirectUri:', redirectUri);
  // console.log('REACT_APP_DEPLOYMENT:', process.env.REACT_APP_DEPLOYMENT);

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUri}
    >
      {children}
    </Auth0Provider>
  );
};
