import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import axios from 'axios';
import { XMarkIcon } from '@heroicons/react/24/outline';


const FomoNotification = ({orgid, threshold}) => {
  const [notifications, setNotifications] = useState([]);
  const [currentNotification, setCurrentNotification] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [showNotifications, setShowNotifications] = useState(true);
  const [isHoveringClose, setIsHoveringClose] = useState(false);

  
  const server = require("../../connections/serverURL");
  var connection_server = server.getServer();

  const apiUrl = connection_server + '/stats/fomo';

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        let url = apiUrl;
        const params = new URLSearchParams();
        
        if (orgid) params.append('orgid', orgid);
        if (threshold) params.append('threshold', threshold);
        
        if (params.toString()) {
          url += `?${params.toString()}`;
        }
        console.log(url);

        const response = await axios.get(url);
        const filteredNotifications = response.data.filter(notification => 
          notification.donation !== null && 
          notification.daysAgo !== null
        );
        setNotifications(filteredNotifications);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
  }, [orgid, threshold]);

  useEffect(() => {
    if (notifications.length > 0 && showNotifications) {
      let index = 0;
      let timeout = null;
      let hideTimeout = null;
  
      const showNextNotification = () => {
        if (index < notifications.length) {
          setCurrentNotification(notifications[index]);
          setIsVisible(true);
          setNotificationCount(prev => prev + 1);
  
          // Clear the previous hideTimeout
          clearTimeout(hideTimeout);
  
          hideTimeout = setTimeout(() => {
            setIsVisible(false);
            index++;
            timeout = setTimeout(showNextNotification, 10000); // Wait 10 seconds before showing the next notification
          }, 10000); // Hide the notification after 10 seconds
        }
      };
  
      timeout = setTimeout(showNextNotification, 6000);
  
      return () => {
        clearTimeout(timeout);
        clearTimeout(hideTimeout);
      };
    }
  }, [notifications, showNotifications]);

  const handleClose = (e) => {
    e.stopPropagation(); // Prevent the click from triggering the match action
    setShowNotifications(false);
    setIsVisible(false);
  };

  const handleMatchClick = (e) => {
    e.preventDefault();
    const currentUrl = new URL(window.location.href);
    const orgId = currentUrl.pathname.split('/')[1]; // Extract the orgId from the current path
    const donationAmount = Math.floor(currentNotification.donation);
    
    // Construct the new URL
    const newUrl = `/${orgId}/Set-Donation?donate=${donationAmount}`;
    
    // Redirect to the new URL
    window.location.href = newUrl;
  };

  if (!currentNotification || !showNotifications || !isVisible) return null;

  const formattedDonation = `$${currentNotification.donation.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })}`;

  const roundedDownDonation = Math.floor(currentNotification.donation);

  return (
    <div 
      className={`fixed bottom-4 left-4 right-4 md:left-auto md:right-4 flex justify-center md:justify-end animate-fade-in-out`}
      onClick={orgid && !isHoveringClose ? handleMatchClick : undefined}
    >
      <div className={`
        relative bg-white shadow-lg rounded-lg p-4 pr-10 w-full max-w-sm md:max-w-md
        transition-all duration-300 ease-in-out
        ${orgid && !isHoveringClose ? `
          cursor-pointer 
          hover:bg-blue-50 hover:shadow-xl hover:scale-105
          active:shadow-inner active:scale-100 active:translate-y-0.5
        ` : ''}
      `}>
        <button 
          onClick={handleClose}
          onMouseEnter={() => setIsHoveringClose(true)}
          onMouseLeave={() => setIsHoveringClose(false)}
          className={`
            absolute top-2 right-2 p-1 rounded-full
            transition-colors duration-300
            ${isHoveringClose ? 'bg-gray-200' : 'hover:bg-gray-100'}
          `}
        >
          <XMarkIcon className="h-5 w-5 text-gray-600" />
        </button>
        <div className="flex items-center space-x-4">
          <img 
            src="https://process.filestackapi.com/ApqhzE1ldTzuKSj33adqez/resize=width:170,height:170/https://cdn.filestackcontent.com/hs5Q5LvpQsGfCsZH8f9M" 
            alt="Notification Icon" 
            className="w-12 h-12 rounded-full flex-shrink-0"
          />
          <div className="flex-1 min-w-0 flex flex-col justify-center">
            <p className="text-sm leading-snug mb-1">
              {orgid 
                ? <>A member recently donated <span className="font-bold">${currentNotification.donation.toLocaleString()}</span>.</>
                : <>A GiveMasjid masjid received <span className="font-bold">${currentNotification.donation.toLocaleString()}</span>.</>
              }
            </p>
            <div className="flex justify-between items-center">
              {orgid ? (
                <p className="text-sm text-blue-600 font-semibold group-hover:text-blue-800 transition-colors duration-300">
                  Click to Match!
                </p>
              ) : (
                <div></div> // Empty div to maintain layout when orgid is not present
              )}
              <span className="text-xs text-gray-400">
                {currentNotification.daysAgo} days ago
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



export default FomoNotification;