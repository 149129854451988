// -------------------
// donationWrapper.js |
// -------------------
// Serves as the second-wrapper for the application once an organization is specified. Core responsibility is to operate the donation flow.
// This wrapper controls all points from setting the donation through the stripe checkout. It does not include the donation successful page.
// =========================================================================================================================================


// DEPENDENCIES
// ========================================
import React, {useState, useEffect} from 'react'
import { useNavigate, Navigate, Outlet, useOutletContext, useParams, useSearchParams} from "react-router-dom";
import axios from 'axios';

// COMPONENTS
// ========================================
import Header from '../sub-components/header.jsx'
import CurrentDonation from '../sub-components/currentDonation.jsx'
import AddDonation from "../sub-components/addDonation.jsx"
import GoalTracker from "../sub-components/goalTracker.jsx"
import Footer from "../sub-components/footer.jsx"
import Loader from '../sub-components/loader.jsx'
	
// STYLESHEETS  
// ========================================
import '../../index.css';
import check from '../../assets/noun-check.svg'

// Server URL 
const server = require("../../connections/serverURL");
var connection_server = server.getServer();

// COMPONENT
// ========================================
function DonationWrapper() {

	// Context Data for retrieving the org data, session data, and load status
	const outletContext = useOutletContext();
  	const sessionID = outletContext.sessionID;
  	const setsessionID = outletContext.setSessionID;
  	const isLoading = outletContext.isLoading;
  	const setIsLoading = outletContext.setIsLoading;
  	const serverURL = outletContext.serverURL;
  	const orgID = outletContext.orgID;
  	const orgData = outletContext.orgData;
  	const currentDonation = outletContext.currentDonation;
  	const setCurrentDonation = outletContext.setCurrentDonation;
  	const dbsClicked = outletContext.dbsClicked;
  	const setdbsClicked = outletContext.setdbsClicked;
	const subscriptionDonation = outletContext.subscriptionDonation;
	const setSubscriptionDonation = outletContext.setSubscriptionDonation;
	const subscriptionFrequency = outletContext.subscriptionFrequency;
	const setSubscriptionFrequency = outletContext.setSubscriptionFrequency;
	const toggleSubscriptionCount = outletContext.toggleSubscriptionCount;
	const setToggleSubscriptionCount = outletContext.setToggleSubscriptionCount;
	const subscriptionCount = outletContext.subscriptionCount;
	const setSubscriptionCount = outletContext.setSubscriptionCount
  	const terminalMode = outletContext.terminalMode;
  	const terminalReader = outletContext.terminalReader;
  	const terminalLive = outletContext.terminalLive;
	const phoneCapture = outletContext.phoneCapture;
	const setPhoneCapture = outletContext.setPhoneCapture;

	const donationCauses = outletContext.donationCauses;
	const setDonationCauses = outletContext.setDonationCauses;
	const specifiedCause = outletContext.specifiedCause;
	const setSpecifiedCause = outletContext.setSpecifiedCause;
	const backgroundImage = outletContext.backgroundImage;
	const setBackgroundImage = outletContext.setBackgroundImage;
	const studentNames = outletContext.studentNames;
	const setStudentNames = outletContext.setStudentNames;
	const schoolName =outletContext.schoolName;
	const schoolEnrollmentActive = outletContext.schoolEnrollmentActive;
	const schoolOneTimeDonation = outletContext.schoolOneTimeDonation;
	const schoolMonthlyDonation = outletContext.schoolMonthlyDonation;
	const isDemo = outletContext.isDemo

	const currency = outletContext.currency;
	const setCurrency = outletContext.setCurrency;

	const fomoActive = outletContext.fomoActive;
	const setFomoActive = outletContext.setFomoActive;
	const fomoThreshold = outletContext.fomoThreshold

	// Search Params
  	const [searchParams, setSearchParams] = useSearchParams();

	// outletData variable for passing along org data, sessionID, server URL, and currentDonation to other components
	var outletData = {
		sessionID: sessionID,
		serverURL: serverURL,
		orgID: orgID,
		orgData: orgData,
		currentDonation: currentDonation,
		setCurrentDonation: setCurrentDonation,
		dbsClicked: dbsClicked,
		setdbsClicked: setdbsClicked,
		donationCauses: donationCauses,
		setDonationCauses: setDonationCauses,
		specifiedCause: specifiedCause,
		setSpecifiedCause: setSpecifiedCause,
		subscriptionDonation: subscriptionDonation,
		setSubscriptionDonation: setSubscriptionDonation,
		subscriptionFrequency: subscriptionFrequency,
		setSubscriptionFrequency: setSubscriptionFrequency,
		toggleSubscriptionCount: toggleSubscriptionCount,
		setToggleSubscriptionCount: setToggleSubscriptionCount,
		subscriptionCount: subscriptionCount,
		setSubscriptionCount: setSubscriptionCount,
		terminalMode: terminalMode,
		terminalReader: terminalReader,
		terminalLive: terminalLive,
		phoneCapture: phoneCapture,
		setPhoneCapture: setPhoneCapture,
		setStudentNames: setStudentNames,
		studentNames: studentNames,
		schoolName: schoolName,
		schoolEnrollmentActive: schoolEnrollmentActive,
		schoolOneTimeDonation: schoolOneTimeDonation,
		schoolMonthlyDonation: schoolMonthlyDonation,
		isDemo: isDemo,
		currency: currency,
		setCurrency: setCurrency,
		fomoActive: fomoActive,
		setFomoActive: setFomoActive,
		fomoThreshold: fomoThreshold
	}

	// Side for checking if there is a valid cause or subscription specified in the params
  	var params = useParams();
  	var causeSearchParams = searchParams.get('cause');
  	var donationAmountSearchParams = searchParams.get("donate");
	var subscriptionSearchParams = searchParams.get("recurring");
	var subscriptionFrequencySearchParams = searchParams.get("freq");
	var subscriptionCountParams = searchParams.get("count");
  	var causeParam = "";

  	if(params.cause){
  		causeParam = params.cause;
  	}
  	else if(causeSearchParams != null) {
  		causeParam = causeSearchParams;
  	}
	// Then check if the donation is lower enough to kill the fomo feed
	useEffect(() => {
		if (orgData.account_features?.donation_feed) {
			if (currentDonation < fomoThreshold) {
				setFomoActive(true);
			} else {
				setFomoActive(false);
			}
		} else {
			setFomoActive(false);
		}
	}, [currentDonation, orgData.account_features, fomoThreshold]);

	// Scroll to the top on donation pages
	useEffect(() => {
	  window.scrollTo(0,30);
	}, [location]);

  	// Check if we are in terminal mode
  	useEffect(() => {
  		// console.log(terminalMode);
  		// console.log(terminalReader);
  	})

	// Establish the Currency (if not USD) 
	useEffect(() => {
		if(currency != "USD") {
			setCurrency(orgData.account_info.currency);
		}
	}, [orgData])
			
	// Scroll to the top of the page 
	// useEffect(() => {

	// 	var element = document.getElementById("scrollspot");
	// 	element.scrollIntoView();
	
	// }, [location])

  	// Check if search parameter exists for donation -- if so update the displayed value
  	useEffect(() => {
  		if(Number.isFinite(parseFloat(donationAmountSearchParams))) {
  			setCurrentDonation(parseFloat(donationAmountSearchParams))
  		}
  	}, [])

  	// Update the search parameter to reflect the current donation
  	useEffect(() => {
  		if(Number.isFinite(currentDonation)) {
			searchParams.set("donate", currentDonation);
			setSearchParams(searchParams);
  		}
  	}, [currentDonation])


	// Check if search parameter exists for subscription donation -- if so update the displayed value
	useEffect(() => {
		// console.log(subscriptionSearchParams);
		if(subscriptionSearchParams == "true") {
			setSubscriptionDonation(subscriptionSearchParams);
			setSubscriptionFrequency(subscriptionFrequencySearchParams);
		}

		if(subscriptionCountParams != null) {
			setToggleSubscriptionCount(true);
			setSubscriptionCount(subscriptionCountParams);
		}
	}, [])

	// Ensure the background image is set
	useEffect(() => {
		try {
			setBackgroundImage(orgData.account_theme.wallpaper_image);
		}
		catch(err) {}
	}, [orgData])

	// Update the search parameter to reflect the subscription status
	useEffect(() => {
		if (subscriptionDonation == "true" || subscriptionDonation == true) {
			searchParams.set("recurring", subscriptionDonation);
			searchParams.set("freq", subscriptionFrequency);
			setSearchParams(searchParams);
		}
		else {
			searchParams.delete("recurring");
			searchParams.delete("freq");
			setSearchParams(searchParams);
		}

		if (subscriptionCount > 0 && (toggleSubscriptionCount=="true" || toggleSubscriptionCount==true)) {
			searchParams.set("count", subscriptionCount);
			setSearchParams(searchParams);
		}
		else {
			searchParams.delete("count");
			setSearchParams(searchParams);
		}

	}, [subscriptionDonation, subscriptionFrequency, toggleSubscriptionCount, subscriptionCount])


  	useEffect(() => {

  		if(params.cause || causeSearchParams != null){

			// Build the URL to call
		 	var designationURL = connection_server + '/designations/?orgid=' + params.orgID;

		 	// Axios Call
		 	const designationAPICall = async() => {
		 		const result = await axios(designationURL);

		 		// Loop through array and see if there is a matching pathname
		 		for (var i=0; i < result.data.designations.length; i++) {	
		 			if(result.data.designations[i].pathname == causeParam){
		 				setSpecifiedCause(result.data.designations[i]);
		 			}
		 			else {

		 			}
		 		}
		 	};

		 	// Run the API call
		 	designationAPICall();	
  		}

  	}, [params, searchParams])

	// Side Effect for Updating the Session in the DB (Runs once)
	useEffect(() => {

		// Build an update session URL
	 	var sessionUpdateStart = serverURL + '/sessions/update/?sessionid=' + sessionID + "&updateparameter=app_data.start_times.donation_page&updatevalue=" + Date.now();

	 	// Update Function
	 	const updateSession = async(url) => {
	 		const result = await axios(url);
	 	};


	 	// Run the Update functions once a sessionID exists
	 	if(sessionID != "") {

		 	// Run the Axios API calls
		 	updateSession(sessionUpdateStart);	 		

	 	}


	}, [])
	
	// Render Function
	// If the app has yet to pull the org data, display the loader.
	if(isLoading == true) {
		return (
			<div className="h-full min-h-screen lg:min-h-0 flex flex-col ">

				<div className="flex-grow lg:flex-grow-0">
					<div className = " ">
						<Loader className=""/>
					</div>
			 	</div>

		    	{/*Page Footer*/}				
		    	<hr id="scrollspot"  className="mt-4 mb-2 w-11/12 mx-auto"/>
				<Footer/>
			</div>

		);
	}
	else {
		return(

			// Page Contents
			<div className="flex flex-col 3xl:mr-3 ">
				
				{/*Page Header*/}
				<div className="grow-1">
					<Header display_name = {orgData.account_info.display_name} server={serverURL} source={orgData.account_theme.header_image} orgid={orgID}/>
				</div>

				{/* Sub Page */}
				<div className="flex-grow w-[99%]">
					<Outlet context={outletData}/>
				</div>

				{/*Goal Tracker - Mobile*/}
				<div className="">

					{/* Account Messages */}

					<GoalTracker left={true} title={false} orgID={orgID} orgType={orgData.account_info.org_type} featureEnabled={orgData.account_features.goal_tracker}/>
					<div className="text-neutral-600 hidden 3xl:block">

						{/* Invisible Text to Force Width */}
						<div className="mx-auto ml-7 pr-3 hidden 3xl:block"><p className = "text-xs 3xl:text-xs 3xl:mr-2 3xl:opacity-[65%] 3xl:invisible">The Prophet, upon be peace, said: “The believer's shade on the Day of Resurrection will be his charity. (Al-</p></div>

					</div>
				</div>

				
				{/*Page Footer*/}
				<Footer/>

			</div>

		)

	}


}


// EXPORT COMPONENT
// ========================================
export default DonationWrapper;