// -----------------------
// companyLandingPage.js |
// -----------------------
// Serves as a backup landing page in case a user decideds to remove the orgid (e.g. donate.givemasjid.com). This provides them a quick 
// snapshot of what GiveMasjid is. It's not meant to be a regularly visited website.
// =========================================================================================================================================

// DEPENDENCIES
// ========================================
import React, { useState, useEffect } from 'react'
import { Link, useOutletContext, useSearchParams, useNavigate } from "react-router-dom";
import axios from 'axios';

import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend);

// AWS
import AWS from 'aws-sdk';
AWS.config.update({
	accessKeyId: 'AKIARCL3WWQZVWZVZVXY',
	secretAccessKey: '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',
	region: 'us-east-1',
});

const s3 = new AWS.S3();


const { DateTime } = require("luxon");


// SERVER CONNECTION
// ========================================
const server = require("../../connections/serverURL");
var server_url = server.getServer();


// Authentication
import { useAuth0 } from "@auth0/auth0-react";


// COMPONENTS
// ========================================
import BannerImage from "../sub-components/bannerImage.jsx"
import HeaderImage from "../sub-components/HeaderImage.jsx"
import Footer from "../sub-components/footer.jsx"

// ICONS
// ========================================
import icon from '../../assets/mosque.svg'

// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'


// COMPONENT
// ========================================

function GlobalManager(params) {

	// Authentication
	const { user, getAccessTokenSilently } = useAuth0();
	let navigate = useNavigate();
	let [searchParams, setSearchParams] = useSearchParams();

	const outletContext = useOutletContext();
	const serverURL = outletContext.serverURL;
	const setBackgroundImage = outletContext.setBackgroundImage

	// const tabs = ["Accounts", "Users", "Tools", "Reports",];
	const tabs = ["Accounts", "Users", "Tools", "Reports",];

	const [tabToDisplay, setTabToDisplay] = useState("accounts");
	const [isLoading, setIsLoading] = useState(false);

	// Reporting
	const [liveStats, setLiveStats] = useState([]);
	const [monthlyStats, setMonthlyStats] = useState([]);
	const [topDonors, setTopDonors] = useState([]);


	const [masjidStatsAllTime, setMasjidStatsAllTime] = useState([]);
	const [masjidStatsLastThreeMonths, setMasjidStatsLastThreeMonths] = useState([]);
	const [showAllTime, setShowAllTime] = useState(true);

	const [accountOption, setAccountOption] = useState("create");

	const [selectedFiles, setSelectedFiles] = useState([]);
	const [fileUrls, setFileUrls] = useState([]);
	const [toolsOption, setToolsOption] = useState("welcome");

	// Email ID for sendgrid
	const [templateId, setTemplateId] = useState('');


	// Masjid creator
	const [formData, setFormData] = useState({
		orgid: '',
		organizationName: '',
		ein: '',
		website: '',
		addressStreet: '',
		addressCity: '',
		addressState: '',
		addressZip: '',
		displayName: '',
		stripeID: '',
		verifiedNonProfit: 'no',
		stripePercentFee: '0.027',
		stripeFixedFee: '0.30',
		appPercentFee: '0.008',
		appFixedFee: '0.00'
	});

	const [editStates, setEditStates] = useState({
		accounts: false,
		designations: false,
		goals: false,
		schools: false,
		feeds: false,
		printables: false,
		users: false
	});

	const [customOrgId, setCustomOrgId] = useState('');
	const [submittedOrgId, setSubmittedOrgId] = useState('');
	const [searchOrgId, setSearchOrgId] = useState('');
	const [accountData, setAccountData] = useState({
		accounts: '',
		schools: '',
		goals: '',
		designations: '',
		users: '',
		printables: ''
	});

	const handleSearchChange = (e) => {
		setSearchOrgId(e.target.value);
	};

	const handleCopy = (text) => {
		navigator.clipboard.writeText(text).then(() => {
			// alert('Copied to clipboard!');
		}).catch(err => {
			console.error('Failed to copy: ', err);
		});
	};

	const uploadFileToS3 = async (file, prefix) => {
		if (!file) return null;

		const filename = `${prefix}${Date.now()}-${file.name}`;
		const params = {
			Bucket: 'sadaqahqr-web',
			Key: filename,
			Body: file,
			ContentType: file.type, // Set the appropriate content type based on the file type
		};

		try {
			const uploadResult = await s3.upload(params).promise();
			return uploadResult.Location; // Location is the public URL of the uploaded file
		} catch (error) {
			console.error('Error uploading file:', error);
			return null;
		}
	};


	const handleSearch = async () => {
		try {
			const token = await getAccessTokenSilently({
				audience: 'https://donate.givemasjid.com',
				scope: 'read:accounts'
			});

			const response = await axios.get(`${server_url}/accounts`, {
				params: { orgid: searchOrgId.toUpperCase() },
				headers: {
					Authorization: `Bearer ${token}`
				}
			});

			const { accounts, schools, goals, designations, users, printables } = response.data;

			setAccountData({
				accounts: JSON.stringify(accounts, null, 2),
				schools: JSON.stringify(schools, null, 2),
				goals: JSON.stringify(goals, null, 2),
				designations: JSON.stringify(designations, null, 2),
				users: JSON.stringify(users, null, 2),
				printables: JSON.stringify(printables, null, 2)
			});
		} catch (error) {
			console.error('Error fetching account data:', error);
			alert('An error occurred while fetching account data. Please try again.');
		}
	};

	const handleEdit = (collection) => {
		setEditStates(prev => ({ ...prev, [collection]: true }));
	};

	const handleCancel = (collection) => {
		setEditStates(prev => ({ ...prev, [collection]: false }));
		// Here you would also reset the textarea content to its original value
	};

	const handleSave = (collection) => {
		setEditStates(prev => ({ ...prev, [collection]: false }));
		// Here you would implement the save functionality
	};

	// Users Pane
	const [userOption, setUserOption] = useState("create");
	const [newUser, setNewUser] = useState({ name: '', email: '', password: '', org: '' });
	const [users, setUsers] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');

	const handleNewUserChange = (e) => {
		const { name, value } = e.target;
		setNewUser(prev => ({ ...prev, [name]: value }));
	};

	const createUser = async (e) => {
		e.preventDefault();
		try {

			const token = await getAccessTokenSilently({
				audience: 'https://donate.givemasjid.com',
			});


			const response = await axios.post(`${server_url}/users`, {
				email: newUser.email,
				password: newUser.password,
				name: newUser.name,
				orgName: newUser.org
			}, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json'
				}
			});

			if (response.status === 201) {
				alert('User created successfully!');
				setNewUser({ name: '', email: '', password: '', org: '' });
			} else {
				alert('Failed to create user. Please try again.');
			}
		} catch (error) {
			console.error('Error creating user:', error);
			alert('An error occurred while creating the user. Please try again.');
		}
	};

	const fetchUsers = async () => {
		try {

			const token = await getAccessTokenSilently({
				audience: 'https://donate.givemasjid.com',
				scope: 'read:users'
			});

			const response = await axios.get(`${server_url}/users`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});

			// Assuming the server returns an array of user objects
			setUsers(response.data.users);
		} catch (error) {
			console.error('Error fetching users:', error);
		}
	};

	useEffect(() => {
		if (tabToDisplay === "users" && userOption === "view") {
			fetchUsers();
		}
	}, [tabToDisplay, userOption]);

	// Fetch Stats
	const fetchStats = async () => {
		setIsLoading(true);
		try {

			const token = await getAccessTokenSilently({
				audience: 'https://donate.givemasjid.com', // Replace with your actual API identifier
				scope: 'read:stats'
			});

			// const statsURL = "https://donate.givemasjid.com/stats/app?orgid=" + user.org;
			const orgIdToUse = submittedOrgId || user.org; // Use submittedOrgId if provided, otherwise use user.org
			const statsURL = `${server_url}/stats/app?orgid=${submittedOrgId || user.org}`;

			// console.log("using correct domain")
			// console.log(statsURL);

			const response = await axios.get(statsURL, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});

			// console.log("Response received:", response.status, response.statusText);
			//   console.log("Response data:", response.data);

			const rawStats = response.data;
			console.log(rawStats);
			// Select only the stats we want to display
			const displayStats = [
				{ name: 'Donations Raised', value: `${formatterCurrency.format(rawStats.totalAmount)}` },
				{ name: 'Donation Count', value: `${formatterNumber.format(rawStats.count)}` },
				{ name: 'Accounts', value: `${formatterNumber.format(rawStats.uniqueOrgCount)}` },
				{ name: 'Application Fees', value: `${formatterCurrency.format(rawStats.totalApplicationFee)}` },
				{ name: 'Average Donation Size', value: `${formatterCurrency.format(rawStats.avgAmount)}` },
				{ name: 'Largest Donation', value: `${formatterCurrency.format(rawStats.largestDonation)}` },
				{ name: 'Donor Count', value: `${formatterNumber.format(rawStats.uniqueCustomerCount)}` },
				{ name: 'Subscriber Count', value: `${formatterNumber.format(rawStats.uniqueSubscriberCount)}` },
				{ name: 'Total Subscription Payments ($)', value: `${formatterCurrency.format(rawStats.subscriptionAmount)}` },
				{ name: 'Subscription Payment Count', value: `${formatterNumber.format(rawStats.subscriptionCount)}` },
			];

			console.log(displayStats)

			setLiveStats(displayStats);
			setMonthlyStats(rawStats.monthlyStats || []);
			setMasjidStatsAllTime(response.data.masjidStatsAllTime || []);
			setMasjidStatsLastThreeMonths(response.data.masjidStatsLastThreeMonths || []);
			setTopDonors(rawStats.topCustomers || []);


		} catch (error) {
			console.error('Error fetching stats:', error);
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.error("Error data:", error.response.data);
				console.error("Error status:", error.response.status);
				console.error("Error headers:", error.response.headers);
			} else if (error.request) {
				// The request was made but no response was received
				console.error("No response received:", error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.error('Error message:', error.message);
			}
		} finally {
			setIsLoading(false);
		}
	};

	// Format the Currency
	var formatterCurrency = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0
	});

	// Format numbers with commas
	var formatterNumber = new Intl.NumberFormat('en-US', {
		style: 'decimal',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
		useGrouping: true
	});


	//   Prepare Chart
	const prepareChartData = () => {
		const sortedStats = [...monthlyStats].sort((a, b) => a.month.localeCompare(b.month));
		return {
			labels: sortedStats.map(stat => stat.month),
			datasets: [
				{
					label: 'Donation Amount',
					data: sortedStats.map(stat => stat.amount),
					borderColor: 'rgb(75, 192, 192)',
					yAxisID: 'y',
					tension: 0.1
				},
				{
					label: 'Donation Count',
					data: sortedStats.map(stat => stat.count),
					borderColor: 'rgb(255, 99, 132)',
					yAxisID: 'y1',
					tension: 0.1
				}
			]
		};
	};

	const prepareBarChartData = () => {
		const data = showAllTime ? masjidStatsAllTime : masjidStatsLastThreeMonths;
		return {
			labels: data.map(item => item.orgname),
			datasets: [
				{
					label: 'Total Donations',
					data: data.map(item => item.totalAmount),
					backgroundColor: 'rgba(75, 192, 192, 0.6)',
					borderColor: 'rgba(75, 192, 192, 1)',
					borderWidth: 1
				}
			]
		};
	};

	const barChartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		indexAxis: 'y', // This changes the chart to horizontal
		scales: {
			x: { // Swap x and y
				beginAtZero: true,
				title: {
					display: true,
					text: 'Total Raised ($)'
				}
			},
			y: { // Swap x and y
				title: {
					display: true,
					text: 'Account'
				}
			}
		},
		plugins: {
			legend: {
				display: false
			},
			title: {
				display: false,
				text: 'Masjid Donation Comparison'
			}
		}
	};

	//   Chart Options
	const chartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		interaction: {
			mode: 'index',
			intersect: false,
		},
		stacked: false,
		scales: {
			y: {
				type: 'linear',
				display: true,
				position: 'left',
				title: {
					display: true,
					text: 'Donation Amount ($)'
				}
			},
			y1: {
				type: 'linear',
				display: true,
				position: 'right',
				title: {
					display: true,
					text: 'Donation Count'
				},
				grid: {
					drawOnChartArea: false,
				},
			},
			x: {
				title: {
					display: true,
					text: 'Month'
				}
			}
		}
	};

	//   Handle download
	// Download Transactions
	const handleDownload = async () => {
		try {
			const token = await getAccessTokenSilently({
				audience: 'https://donate.givemasjid.com',
				scope: 'read:download'
			});

			const orgIdToUse = submittedOrgId || user.org;
			const downloadUrl = `${server_url}/stats/download?orgid=${orgIdToUse}`;
			console.log(downloadUrl);

			const response = await fetch(downloadUrl, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});

			if (response.ok) {
				const blob = await response.blob();
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = url;
				a.download = `transactions_${user.org}.csv`;
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
			} else {
				console.error('Download failed');
			}
		} catch (error) {
			console.error('Error downloading CSV:', error);
		}
	};

	// Handle form changes
	const handleInputChange = (e) => {
		const { name, value, files } = e.target;
		console.log(name);


		if (name === 'files') {
			console.log("Have some files");
			console.log(files);
			setSelectedFiles(Array.from(files));
		}
		else if (name.includes('.')) {
			const [parentKey, childKey] = name.split('.');
			setFormData(prevData => ({
				...prevData,
				[parentKey]: {
					...prevData[parentKey],
					[childKey]: value
				}
			}));
		} else {
			setFormData(prevData => ({
				...prevData,
				[name]: name === 'orgid' ? value.toUpperCase() : value
			}));
		}
	};

	//   Handle form
	const handleSubmit = async (e) => {
		e.preventDefault();
		if (window.confirm('Are you sure you want to create this account?')) {
			try {
				console.log("writing");

				const token = await getAccessTokenSilently({
					audience: 'https://donate.givemasjid.com',
					scope: 'write:accounts'
				});

				const accountData = {
					account_id: formData.orgid,
					full_name: formData.organizationName,
					ein: formData.ein,
					website: formData.website,
					addressStreet: formData.addressStreet,
					addressCity: formData.addressCity,
					addressState: formData.addressState,
					addressZip: formData.addressZip,
					display_name: formData.displayName,
					stripe_id: formData.stripeID,
					verified_nonprofit: formData.verifiedNonProfit === 'yes',
					stripePercentFee: parseFloat(formData.stripePercentFee),
					stripeFixedFee: parseFloat(formData.stripeFixedFee),
					appPercentFee: parseFloat(formData.appPercentFee),
					appFixedFee: parseFloat(formData.appFixedFee)
				};

				console.log(accountData);
				const formDataToSend = new FormData();

				Object.entries(accountData).forEach(([key, value]) => {
					formDataToSend.append(`accountData[${key}]`, value);
				});

				if (selectedFiles) {
					console.log("We have a file");
					console.log(selectedFiles);

					for (const file of selectedFiles) {
						console.log(file);
						console.log("we can iterate on the files");
						formDataToSend.append('files', file);
					}
				}

				console.log("Hello");
				console.log(formDataToSend);

				const response = await axios.post(`${server_url}/accounts/create`, formDataToSend, {
					headers: {
						Authorization: `Bearer ${token}`,

					}
				});

				if (response.status === 201) {
					alert('Account created successfully!');
					// Reset form after successful submission
					setFormData({
						orgid: '',
						organizationName: '',
						ein: '',
						website: '',
						addressStreet: '',
						addressCity: '',
						addressState: '',
						addressZip: '',
						displayName: '',
						stripeID: '',
						verifiedNonProfit: 'no',
						stripePercentFee: 0.27,
						stripeFixedFee: 0.30,
						appPercentFee: 0.08,
						appFixedFee: 0.00
					});
				} else {
					alert('Failed to create account. Please try again.');
				}
			} catch (error) {
				console.error('Error creating account:', error);
				alert('An error occurred while creating the account. Please try again.');
			}
		}
	};


	// Set the initial background image
	useEffect(() => {
		setBackgroundImage("Wallpaper_H4.png")
	}, [])

	// Navigaate away from global manager if the user.org is not "global"
	useEffect(() => {
		if (user.org != "GLOBAL")
			navigate("/dashboard");
	}, [user, searchParams])

	// Set the parameter
	useEffect(() => {

		setSearchParams({ "org": user.org })

	}, [user])

	// Handle Tab Changes
	const handleTabChange = (tab) => {
		setTabToDisplay(tab);
	};

	// Fetch reporting data
	useEffect(() => {
		if (tabToDisplay === "reports" && user != null && user !== "") {
			(async () => {
				await fetchStats();
			})();
		}
	}, [user, tabToDisplay, submittedOrgId]);

	return (

		<div className="flex flex-col h-full">
			<HeaderImage server={server_url} source="Header_1.png" />

			<div className="mt-3 w-11/12 mx-auto">
				<img className="h-14 mt-2 mb-3 text-center mx-auto" src={icon} alt="Icon" />
				<h1 className="text-3xl text-center font-bold mb-1">Global Account Manager</h1>
				{/* Code for handling whether we are displaying settings, reporting, or printables */}
				<div className="flex justify-center mb-5 mt-2">
					<div className="flex justify-center mb-1 mt-2">

						{/* Tab Menus */}
						{tabs.map((tab, index) => (
							<button
								key={tab}
								className={`rounded-2xl border text-center text-xs px-3 py-2 mx-1 ${tabToDisplay === tab.toLowerCase()
									? "bg-amber-400 text-stone-700 font-normal"
									: "text-black bg-transparent hover:bg-gray-100 disabled hover:text-black  active:bg-gray-400 font-light"
									}`}
								onClick={() => handleTabChange(tab.toLowerCase())}

							>
								{tab}
							</button>
						))}


					</div>

				</div>

				<hr className="my-4 mx-auto" />

				{/* Accounts */}
				{tabToDisplay === "accounts" && (
					<div className="mt-4">
						<h1 className="text-xl text-center font-bold mb-4">Account Management</h1>
						<div className="flex justify-center mb-6">
							<button
								onClick={() => setAccountOption("create")}
								className={`px-4 py-2 mx-1 rounded-xl text-sm ${accountOption === "create"
									? "bg-theme-100 text-white"
									: "bg-gray-200 text-gray-700"
									}`}
							>
								Create Account
							</button>
							<button
								onClick={() => setAccountOption("view")}
								className={`px-4 py-2 mx-1 rounded-xl text-sm ${accountOption === "view"
									? "bg-theme-100 text-white"
									: "bg-gray-200 text-gray-700"
									}`}
							>
								View Account
							</button>
						</div>

						{accountOption === "create" ? (

							<form onSubmit={handleSubmit} className="space-y-6">
								<div>

									<h1 className="text-md mt-6 font-semibold mb-1">OrgID</h1>
									<input type="text" name="orgid" value={formData.orgid} onChange={handleInputChange} className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm" required />
								</div>
								<div>
									<h1 className="text-md mt-6 font-semibold mb-1">Display Name</h1>
									<input type="text" name="displayName" value={formData.displayName} onChange={handleInputChange} className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm" required />
								</div>
								<div>
									<h1 className="text-md mt-6 font-semibold mb-1">Organization Full Name</h1>
									<input type="text" name="organizationName" value={formData.organizationName} onChange={handleInputChange} className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm" required />
								</div>
								<div>
									<h1 className="text-md mt-6 font-semibold mb-1">EIN</h1>
									<input type="text" name="ein" value={formData.ein} onChange={handleInputChange} className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm" required />
								</div>
								<div>
									<h1 className="text-md mt-6 font-semibold mb-1">Website</h1>
									<input type="url" name="website" value={formData.website} onChange={handleInputChange} className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm" required />
								</div>
								<div>
									<h1 className="text-md mt-6 font-semibold mb-1">Address</h1>
									<input type="text" name="addressStreet" value={formData.addressStreet} onChange={handleInputChange} placeholder="Street" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm mb-2" required />
									<input type="text" name="addressCity" value={formData.addressCity} onChange={handleInputChange} placeholder="City" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm mb-2" required />
									<input type="text" name="addressState" value={formData.addressState} onChange={handleInputChange} placeholder="State" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm mb-2" required />
									<input type="text" name="addressZip" value={formData.addressZip} onChange={handleInputChange} placeholder="ZIP" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm" required />
								</div>
								<div>
									<h1 className="text-md mt-6 font-semibold mb-1">StripeID</h1>
									<input type="text" name="stripeID" value={formData.stripeID} onChange={handleInputChange} className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm" required />
								</div>
								<div>
									<h1 className="text-md mt-6 font-semibold mb-1">Verified Non-Profit</h1>
									<div className="mt-2">
										<label className="inline-flex items-center mr-4">
											<input
												type="radio"
												name="verifiedNonProfit"
												value="yes"
												checked={formData.verifiedNonProfit === 'yes'}
												onChange={handleInputChange}
												className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
											/>
											<span className="ml-2">Yes</span>
										</label>
										<label className="inline-flex items-center">
											<input
												type="radio"
												name="verifiedNonProfit"
												value="no"
												checked={formData.verifiedNonProfit === 'no'}
												onChange={handleInputChange}
												className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
											/>
											<span className="ml-2">No</span>
										</label>
									</div>
								</div>


								<div>
									<h1 className="text-md mt-6 font-semibold mb-1">Stripe Fee</h1>
									<input type="number" name="stripeFee.percentFee" value={formData.stripePercentFee} onChange={handleInputChange} placeholder="Percent Fee" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm mb-2" step="0.01" required />
									<input type="number" name="stripeFee.fixedFee" value={formData.stripeFixedFee} onChange={handleInputChange} placeholder="Fixed Fee" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm" step="0.01" required />
								</div>
								<div>
									<h1 className="text-md mt-6 font-semibold mb-1">Application Fee</h1>
									<input type="number" name="applicationFee.percentFee" value={formData.appPercentFee} onChange={handleInputChange} placeholder="Percent Fee" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm mb-2" step="0.01" required />
									<input type="number" name="applicationFee.fixedFee" value={formData.appFixedFee} onChange={handleInputChange} placeholder="Fixed Fee" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm" step="0.01" required />
								</div>

								<div>
									<h1 className="text-md mt-6 font-semibold mb-3">Upload Files (PDFs)</h1>
									<input
										type="file"
										name="files"
										multiple
										accept=".pdf"
										className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm"
										onChange={handleInputChange}
									/>
								</div>
								<button type="submit" className="w-full p-2 bg-theme-100 text-white rounded-sm hover:bg-theme-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-200">
									Submit
								</button>
							</form>

						) :   // View Account section
							<div>
								<form className="space-y-6">
									<div>
										<h1 className="text-md mt-6 font-semibold mb-1">OrgID</h1>
										<div className="flex justify-between items-center">
											<input
												type="text"
												name="orgid"
												value={searchOrgId}
												onChange={handleSearchChange}
												className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm mr-2"
												required
											/>
											<button
												type="button"
												className="px-4 py-2 bg-blue-200 bg-transparent border border-1 border:zinc-800 text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-200"
												onClick={handleSearch}
											>
												Search
											</button>
										</div>
									</div>

									{['Accounts', 'Schools', 'Goals', 'Designations', 'Users', 'Printables'].map((collection) => (
										<div key={collection}>
											<h1 className="text-md mt-6 font-semibold mb-1">{collection}</h1>
											<div className="relative">
												<textarea
													name={collection.toLowerCase()}
													value={accountData[collection.toLowerCase()]}
													rows="4"
													className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm bg-gray-100"
													readOnly
												></textarea>
												<div className="flex justify-end">
													<button
														type="button"
														onClick={() => handleCopy(accountData[collection.toLowerCase()])}
														className="mt-2 px-3 py-1 bg-transparent text-black border-2 border:zinc-800 text-sm rounded"
													>
														Copy
													</button>
												</div>
											</div>
										</div>
									))}
								</form>
							</div>
						}

					</div>
				)}

				{tabToDisplay === "tools" && (
					<div className="mt-4">
						<h1 className="text-xl text-center font-bold mb-4">Admin Tools</h1>
						<div className="flex justify-center mb-6">
							<button
								onClick={() => setToolsOption("welcome")}
								className={`px-4 py-2 mx-1 rounded-xl text-sm ${toolsOption === "welcome"
									? "bg-theme-100 text-white"
									: "bg-gray-200 text-gray-700"
									}`}
							>
								Welcome Email
							</button>
							<button
								onClick={() => setToolsOption("update")}
								className={`px-4 py-2 mx-1 rounded-xl text-sm ${toolsOption === "update"
									? "bg-theme-100 text-white"
									: "bg-gray-200 text-gray-700"
									}`}
							>
								Update Email
							</button>
							{/* <button
								onClick={() => setToolsOption("print")}
								className={`px-4 py-2 mx-1 rounded-xl text-sm ${toolsOption === "print"
									? "bg-theme-100 text-white"
									: "bg-gray-200 text-gray-700"
									}`}
							>
								Print and Ship
							</button> */}
						</div>

						{toolsOption === "welcome" ? (

							<form
								onSubmit={async (e) => {
									e.preventDefault();
									try {
										const token = await getAccessTokenSilently({
											audience: 'https://donate.givemasjid.com',
											scope: 'write:emails',
										});

										const emailData = {
											orgid: formData.orgID,
											fullname: formData.fullName,
											recipientname: formData.recipientName,
											username: formData.username,
											password: formData.password,
										};

										const uploadedUrls = await Promise.all([
											formData.appImage1 && uploadFileToS3(formData.appImage1, 'app_images/'),
											formData.appImage2 && uploadFileToS3(formData.appImage2, 'app_images/'),
											formData.posterImage && uploadFileToS3(formData.posterImage, 'posters/'),
										].filter(Boolean));

										console.log(uploadedUrls);

										const response = await axios.post(`${server_url}/welcome`, {
											...emailData,
											appImage1: uploadedUrls[0],
											appImage2: uploadedUrls[1],
											posterImage: uploadedUrls[2],
										}, {
											headers: {
												Authorization: `Bearer ${token}`,
											},
										});

										if (response.status === 200) {
											alert('Welcome email sent successfully!');
										} else {
											alert('Failed to send welcome email. Please try again.');
										}
									} catch (error) {
										console.error('Error sending welcome email:', error);
										alert('An error occurred while sending the welcome email. Please try again.');
									}
								}}
								className="space-y-6"
							>
								<div>
									<h1 className="text-md mt-6 font-semibold mb-1">OrgID</h1>
									<input
										type="text"
										name="orgID"
										value={formData.orgID}
										onChange={handleInputChange}
										className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm"
										required
									/>
								</div>
								<div>
									<h1 className="text-md mt-6 font-semibold mb-1">Full Name</h1>
									<input
										type="text"
										name="fullName"
										value={formData.fullName}
										onChange={handleInputChange}
										className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm"
										placeholder="Tariq Ismail"
										required
									/>
								</div>
								<div>
									<h1 className="text-md mt-6 font-semibold mb-1">Addressed Name</h1>
									<input
										type="text"
										name="recipientName"
										value={formData.recipientName}
										onChange={handleInputChange}
										className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm"
										placeholder="Br. Tariq"
										required
									/>
								</div>
								<div>
									<h1 className="text-md mt-6 font-semibold mb-1">Username (Email Address)</h1>
									<input
										type="text"
										name="username"
										value={formData.username}
										onChange={handleInputChange}
										className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm"
										required
									/>
								</div>
								<div>
									<h1 className="text-md mt-6 font-semibold mb-1">Password</h1>
									<input
										type="password"
										name="password"
										value={formData.password}
										onChange={handleInputChange}
										className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm"
										required
									/>
								</div>
								<div>
									<h1 className="text-md mt-6 font-semibold mb-1">App Image #1</h1>
									<input
										type="file"
										name="appImage1"
										onChange={(e) => setFormData({ ...formData, appImage1: e.target.files[0] })}
										className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm"
										required
									/>
								</div>
								<div>
									<h1 className="text-md mt-6 font-semibold mb-1">App Image #2</h1>
									<input
										type="file"
										name="appImage2"
										onChange={(e) => setFormData({ ...formData, appImage2: e.target.files[0] })}
										className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm"
										required
									/>
								</div>
								<div>
									<h1 className="text-md mt-6 font-semibold mb-1">Poster Image</h1>
									<input
										type="file"
										name="posterImage"
										onChange={(e) => setFormData({ ...formData, posterImage: e.target.files[0] })}
										className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm"
										required
									/>
								</div>
								<button
									type="submit"
									disabled={
										!formData.orgID ||
										!formData.recipientName ||
										!formData.username ||
										!formData.password ||
										!formData.appImage1 ||
										!formData.appImage2 ||
										!formData.posterImage
									}
									className={`w-full p-2 rounded-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${formData.orgID &&
										formData.recipientName &&
										formData.username &&
										formData.password &&
										formData.appImage1 &&
										formData.appImage2 &&
										formData.posterImage
										? 'bg-theme-100 text-white hover:bg-theme-200 focus:ring-theme-200'
										: 'bg-gray-300 text-gray-500 cursor-not-allowed'
										}`}
								>
									Send Email
								</button>
							</form>
						)
							: toolsOption === "update" ? (
								<div>
									{/* Update Email form */}
									<form
										onSubmit={async (e) => {
											e.preventDefault();
											try {
												const token = await getAccessTokenSilently({
													audience: 'https://donate.givemasjid.com',
													scope: 'write:emails',
												});

												const response = await axios.get(`${server_url}/email/update?templateId=${templateId}`, {
													headers: {
														Authorization: `Bearer ${token}`,
													},
												});

												if (response.status === 200) {
													alert(response.data.message);
													// console.log(response.data.message) // Log the success message from the server
												} else {
													alert('Failed to send update email. Please try again.');
												}
											} catch (error) {
												console.error('Error sending update email:', error);
												alert('An error occurred while sending the update email. Please try again.');
											}
										}}
										className="space-y-6"
									>
										<div>
											<h1 className="text-md mt-6 font-semibold mb-1">SendGrid Template ID</h1>
											<input
												type="text"
												name="templateId"
												value={templateId}
												onChange={(e) => setTemplateId(e.target.value)}
												className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm"
												required
											/>
										</div>
										<button
											type="submit"
											disabled={!templateId}
											className={`w-full p-2 rounded-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${templateId
													? 'bg-theme-100 text-white hover:bg-theme-200 focus:ring-theme-200'
													: 'bg-gray-300 text-gray-500 cursor-not-allowed'
												}`}
										>
											Send Update Email
										</button>
									</form>
								</div>
							)
								: (
									<div>
										<form onSubmit={async (e) => {
											e.preventDefault();
											// Handle form submission here
										}} className="space-y-6">
											<div>
												<h1 className="text-md mt-6 font-semibold mb-1">OrgID</h1>
												<input
													type="text"
													name="orgID"
													value={formData.orgID}
													onChange={handleInputChange}
													className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm"
													required
												/>
											</div>
											<div>
												<h1 className="text-md mt-6 font-semibold mb-1">Recipient Name</h1>
												<input
													type="text"
													name="recipientName"
													value={formData.recipientName}
													onChange={handleInputChange}
													className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm"
													required
												/>
											</div>
											<div>
												<h1 className="text-md mt-6 font-semibold mb-1">Email</h1>
												<input
													type="text"
													name="recipientEmail"
													value={formData.recipientEmail}
													onChange={handleInputChange}
													className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm"
													required
												/>
											</div>
											<div>
												<h1 className="text-md mt-6 font-semibold mb-1">Phone Number</h1>
												<input
													type="text"
													name="recipientPhone"
													value={formData.recipientPhone}
													onChange={handleInputChange}
													className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm"
													required
												/>
											</div>
											<div>
												<h1 className="text-md mt-6 font-semibold mb-1">Street Address</h1>
												<input
													type="text"
													name="recipientStreet"
													value={formData.recipientStreet}
													onChange={handleInputChange}
													className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm"
													required
												/>
											</div>
											<div>
												<h1 className="text-md mt-6 font-semibold mb-1">City</h1>
												<input
													type="text"
													name="recipientCity"
													value={formData.recipientCity}
													onChange={handleInputChange}
													className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm"
													required
												/>
											</div>
											<div>
												<h1 className="text-md mt-6 font-semibold mb-1">State / Province</h1>
												<input
													type="text"
													name="recipientState"
													value={formData.recipientState}
													onChange={handleInputChange}
													className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm"
													required
												/>
											</div>
											<div>
												<h1 className="text-md mt-6 font-semibold mb-1">Country</h1>
												<input
													type="text"
													name="recipientCountry"
													value={formData.recipientCountry}
													onChange={handleInputChange}
													className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm"
													required
												/>
											</div>
											<div>
												<h1 className="text-md mt-6 font-semibold mb-1">Zipcode</h1>
												<input
													type="text"
													name="recipientZipcode"
													value={formData.recipientZipcode}
													onChange={handleInputChange}
													className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm"
													required
												/>
											</div>
											<div>
												<h1 className="text-md mt-6 font-semibold mb-1">Tabloid File (PDF)</h1>
												<input
													type="file"
													name="tabloidFile"
													onChange={(e) => setFormData({ ...formData, tabloidFile: e.target.files[0] })}
													accept=".pdf"
													className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm"
													required
												/>
											</div>
											<div>
												<h1 className="text-md mt-6 font-semibold mb-1">Flyer File (PDF)</h1>
												<input
													type="file"
													name="flyerFile"
													onChange={(e) => setFormData({ ...formData, flyerFile: e.target.files[0] })}
													accept=".pdf"
													className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm"
													required
												/>
											</div>
											<div>
												<h1 className="text-md mt-6 font-semibold mb-1">Postcard File (PDF)</h1>
												<input
													type="file"
													name="postcardFile"
													onChange={(e) => setFormData({ ...formData, postcardFile: e.target.files[0] })}
													accept=".pdf"
													className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm"
													required
												/>
											</div>
											<div className="space-y-2">
												<button
													type="button"
													onClick={async () => {
														// Handle Get Quote logic here
														try {
															const token = await getAccessTokenSilently({
																audience: 'https://donate.givemasjid.com',
																scope: 'write:print_quote',
															});

															const uploadedUrls = await Promise.all([
																formData.tabloidFile && uploadFileToS3(formData.tabloidFile, 'print_materials/tabloids/'),
																formData.flyerFile && uploadFileToS3(formData.flyerFile, 'print_materials/flyers/'),
																formData.postcardFile && uploadFileToS3(formData.postcardFile, 'print_materials/postcards/'),
															].filter(Boolean));

															const quoteData = {
																...formData,
																tabloidFileUrl: uploadedUrls[0],
																flyerFileUrl: uploadedUrls[1],
																postcardFileUrl: uploadedUrls[2],
															};

															const response = await axios.post(`${server_url}/print/quote`, quoteData, {
																headers: {
																	Authorization: `Bearer ${token}`,
																},
															});

															if (response.status === 200) {
																alert(`Quote: $${response.data.quote}`);
															} else {
																alert('Failed to get quote. Please try again.');
															}
														} catch (error) {
															console.error('Error getting quote:', error);
															alert('An error occurred while getting the quote. Please try again.');
														}
													}}
													className="w-full px-4 py-2 bg-violet-500 text-white rounded-sm hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"


												>
													Get Quote
												</button>
												<button
													type="submit"
													className="w-full px-4 py-2 bg-theme-100 text-white rounded-sm hover:bg-theme-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"

												>
													Print and Ship
												</button>
											</div>
										</form>
									</div>
								)}

					</div>

				)}


				{/* Users  */}
				{tabToDisplay === "users" && (
					<div className="mt-4">
						<h1 className="text-xl text-center font-bold mb-4">User Management</h1>
						<div className="flex justify-center mb-6">
							<button
								onClick={() => setUserOption("create")}
								className={`px-4 py-2 mx-1 rounded-xl text-sm ${userOption === "create"
									? "bg-theme-100 text-white"
									: "bg-gray-200 text-gray-700"
									}`}
							>
								Create User
							</button>
							<button
								onClick={() => setUserOption("view")}
								className={`px-4 py-2 mx-1 rounded-xl text-sm ${userOption === "view"
									? "bg-theme-100 text-white"
									: "bg-gray-200 text-gray-700"
									}`}
							>
								View Users
							</button>
						</div>

						{userOption === "create" ? (
							<form onSubmit={createUser} className="space-y-6">
								<div>
									<h1 className="text-md mt-6 font-semibold mb-1">Name</h1>
									<input type="text" name="name" value={newUser.name} onChange={handleNewUserChange} className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm" required />
								</div>
								<div>
									<h1 className="text-md mt-6 font-semibold mb-1">Email Address</h1>
									<input type="email" name="email" value={newUser.email} onChange={handleNewUserChange} className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm" required />
								</div>
								<div>
									<h1 className="text-md mt-6 font-semibold mb-1">Password</h1>
									<input type="password" name="password" value={newUser.password} onChange={handleNewUserChange} className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm" required />
								</div>
								<div>
									<h1 className="text-md mt-6 font-semibold mb-1">OrgID</h1>
									<input type="text" name="org" value={newUser.org} onChange={handleNewUserChange} className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm" required />
								</div>
								<button type="submit" className="w-full p-2 bg-theme-100 text-white rounded-sm hover:bg-theme-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-200">
									Create User
								</button>
							</form>
						) : (
							<div>
								<h1 className="text-md mt-6 font-semibold mb-1">User Search</h1>

								<input
									type="text"
									placeholder="Search users..."
									value={searchTerm}
									onChange={(e) => setSearchTerm(e.target.value)}
									className="w-full p-2 mb-4 border border-gray-300 rounded-sm"
								/>

								<h1 className="text-md mt- font-semibold mb-1">User Results</h1>
								<div className="w-full overflow-x-auto max-w-xl">

									<table className="min-w-full divide-y divide-gray-200">
										<thead className="bg-gray-50">
											<tr>
												<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
												<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Org</th>
												<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Login</th>
												<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
											</tr>
										</thead>
										<tbody className="bg-white divide-y divide-gray-200">
											{Array.isArray(users) && users.length > 0
												? (
													users
														.filter(
															(user) =>
																user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
																user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
																user.orgName.toLowerCase().includes(searchTerm.toLowerCase())
														)
														.map((user, index) => (
															<tr key={index}>
																<td className="px-6 py-2 whitespace-nowrap text-stone-700 font-light text-sm">
																	{user.name}
																</td>
																<td className="px-6 py-2 whitespace-nowrap text-stone-700 font-light text-sm">
																	{user.orgName.toUpperCase()}
																</td>
																<td className="px-6 py-2 whitespace-nowrap text-stone-700 font-light text-sm">
																	{user.lastLogin}
																</td>
																<td className="px-6 py-2 whitespace-nowrap text-stone-700 font-light text-sm">
																	{user.email}
																</td>
															</tr>
														))
												)
												: (
													console.log("Users array is empty or not an array"),
													[]
												)}
										</tbody>
									</table>
								</div>

							</div>
						)}
					</div>
				)}

				{/* Reports */}
				{tabToDisplay === "reports" && (
					<div>
						{isLoading ? (
							<div className="flex justify-center items-center h-64">
								<div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
							</div>
						) : (
							<div>

								{/* Input Filter */}

								<h1 className="text-md mt-6 font-medium mb-1">OrgID (Optional)</h1>

								<div className="flex justify-between items-center mb-5">

									<input
										type="text"
										value={customOrgId}
										onChange={(e) => setCustomOrgId(e.target.value)}
										placeholder="Enter OrgID"
										className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm mr-2"
									/>
									<button
										onClick={() => setSubmittedOrgId(customOrgId)}
										className="px-4 py-2 bg-blue-200 bg-transparent border border-1 border:zinc-800 text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-200"
									>
										Submit
									</button>
								</div>

								<hr className="my-4 mx-auto" />


								{/* Stats  */}
								<h1 className="text-xl text-center font-bold">All-Time Snapshot</h1>
								<h3 className="text-sm italic text-center font-light text-stone-400 mb-3">All reporting data refreshed every hour.</h3>

								<dl className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2">
									{liveStats.map((item) => (
										<div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
											<dt className="truncate text-xs font-medium text-gray-500">{item.name}</dt>
											<dd className="mt-1 text-medium font-semibold tracking-tight text-gray-900">{item.value}</dd>
										</div>
									))}
								</dl>

								<hr className="my-4 mx-auto" />

								{/* Monthly Statistics Chart */}
								<h1 className="text-xl text-center font-bold mb-1 mt-3">Monthly Trends</h1>
								<div className="mt-4" style={{ height: '400px' }}>
									<Line
										data={prepareChartData()}
										options={chartOptions}
									/>
								</div>

								<hr className="my-4 mx-auto" />

								{/* Top Donors */}
								<h1 className="text-xl text-center font-bold mb-1 mt-3">Top Donors</h1>
								<div className="mt-4 overflow-x-auto max-w-full">
									<table className="max-w-full divide-y divide-gray-200">
										<thead className="bg-gray-50">
											<tr>
												<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
													Name
												</th>
												<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
													Total
												</th>
												<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
													Count
												</th>
												<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
													Email
												</th>
											</tr>
										</thead>
										<tbody className="bg-white divide-y divide-gray-200">
											{topDonors.map((donor, index) => (
												<tr key={index}>
													<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
														{donor.name}
													</td>
													<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
														{formatterCurrency.format(donor.totalAmount)}
													</td>
													<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
														{donor.count}
													</td>
													<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
														{donor.email}
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>

								{/* Account Comparison*/}
								{!submittedOrgId && (
									<>
										<hr className="my-4 mx-auto" />
										<h1 className="text-xl text-center font-bold mb-1 mt-3">Account Comparison</h1>
										<div className="flex justify-center mb-4">
											<button
												onClick={() => setShowAllTime(true)}
												className={`px-6 round-xl py-2 mx-1 text-xs font-semibold rounded-xl focus:outline-none focus:ring-2 focus:ring-theme-200 focus:ring-offset-2 ${showAllTime
													? "bg-theme-100 text-white hover:bg-theme-200"
													: "bg-gray-200 text-gray-700 hover:bg-gray-300"
													}`}
											>
												All Time
											</button>
											<button
												onClick={() => setShowAllTime(false)}
												className={`px-4 py-2  text-xs mx-1 font-semibold rounded-xl focus:outline-none focus:ring-2 focus:ring-theme-200 focus:ring-offset-2 ${!showAllTime
													? "bg-theme-100 text-white hover:bg-theme-200"
													: "bg-gray-200 text-gray-700 hover:bg-gray-300"
													}`}
											>
												Last 3 Months
											</button>
										</div>
										<div className="mt-4" style={{ height: '400px' }}>
											<Bar data={prepareBarChartData()} options={barChartOptions} />
										</div>
									</>
								)}

								{/* Download Transactions Section */}
								<hr className="my-4 mx-auto" />
								<h1 className="text-xl text-center font-bold mb-1 mt-3">Full Donation Report (CSV)</h1>
								<div className="mt-2 flex justify-center">
									<button
										onClick={handleDownload}
										className="px-4 py-2 text-lg font-semibold bg-theme-100 text-white rounded-md hover:bg-theme-200 focus:outline-none focus:ring-2 focus:ring-theme-200 focus:ring-offset-2 disabled:bg-gray-400 disabled:text-gray-200 disabled:cursor-not-allowe"

									>
										Download Transaction History
									</button>
								</div>
							</div>
						)}
					</div>
				)}




				{/*Support Email*/}
				<hr className="my-4 mx-auto" />

				<h1 className="text-xl text-center font-bold mb-1 mt-3">Have a Support Issue?</h1>
				<h2 className="text-lg opacity-50 text-center mb-1 mt-3">Check out our <a className="font-bold" href="https://help.givemasjid.com/">Help Docs</a> or send an email to us at <a href="mailto:support@givemasjid.com">support@givemasjid.com</a></h2>

			</div>
			<Footer />

		</div>
	)
}


// Export Component
export default GlobalManager;



