import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, Link } from "react-router-dom";
import { useEffect, useState } from "react";

function Footer() {
  const { isAuthenticated, logout } = useAuth0();
  const [showLogout, setShowLogout] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setShowLogout(location.pathname === "/dashboard" && isAuthenticated);
  }, [location, isAuthenticated]);

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <div id="footer">
      <hr className="my-4 w-11/12 mx-auto lg:my-3 " />
      <p className="text-xs text-stone-400 font-thin text-center 3xl:text-left 3xl:ml-7 pb-2">
        <span className="hidden 3xl:inline">Powered by </span>
        <a href="https://GiveMasjid.com">GiveMasjid</a> -{" "}
        <a href="https://sadaqahqr-web.s3.amazonaws.com/GiveMasjid_Terms_And_Conditions.pdf">
          {" "}
          Terms of Use
        </a>{" "}
        -{" "}
        <a href="https://sadaqahqr-web.s3.amazonaws.com/GiveMasjid_Privacy_Policy.pdf">
          {" "}
          Privacy{" "}
        </a>{" "}
        - <a href="mailto:support@givemasjid.com"> Support </a>{" "}
        {isAuthenticated ? (
          <Link to="/" onClick={handleLogout}>
            {" "}
            - Logout{" "}
          </Link>
        ) : (
          <Link to="/dashboard"> - Dashboard </Link>
        )}
      </p>
    </div>
  );
}

export default Footer;
